<template>
  <div class="user">
    <!-- <div class="order_top">
      <div class="order_top_name">
        <span>订单收款概览</span>
      </div>
      <div class="order_top_flex">
        <span v-if="id" class="po_compny"
          >2023年至今{{ companyinfo.companyFullName }}所有订单</span
        >
        <img v-if="id" width="120" height="120" :src="companyinfo.companyLogo" alt="" />
        <img
          v-else
          class="img_radius"
          src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/9d5474753c55c4839446dcc657912ac.png"
          alt=""
        />
        <div class="order_top_flex_left">
          <div class="order_top_flex_top">
            <span> 4月您预计获得（元）</span>
            <span class="blue_money"> 6300.35</span>
          </div>
          <div class="order_top_flex_bottom">
            <span> 4月平台获得（元）</span>
            <span class="hui_money"> 6300.35</span>
          </div>
        </div>
        <div class="order_top_flex_right">
          <div class="order_top_flex_top">
            <span> 您往期获得（元）</span>
            <span class="hui_money"> 6300.35</span>
          </div>
          <div class="order_top_flex_bottom">
            <span> 您往期已结算（元）</span>
            <span class="hui_money"> 6300.35</span>
          </div>
        </div>
        <div class="border_left"></div>

        <div class="round_order">
          <span style="font-size: 14px">订单数</span>
          <span class="order_num">100</span>
        </div>
        <div class="round_order_right">
          <span>成功（条）</span>
          <span class="order_num">100</span>
        </div>
        <div class="round_order_right">
          <span>平台成功收款（元）</span>
          <span class="order_num">100</span>
        </div>
        <div class="round_order_right">
          <span class="order_hui">退款（条）</span>
          <span class="order_hui">100</span>
        </div>
        <div class="round_order_right">
          <span class="order_hui">平台退款（元）</span>
          <span class="order_hui">100</span>
        </div>
      </div>
    </div> -->
    <div class="container">
      <div class="form_info">
        <el-form ref="form" :model="queryInfo" label-width="100px">
          <div class="form">
            <div class="comble_txt">
              <el-form-item label="订单类型">
                <el-radio-group v-model="queryInfo.orderType" @change="orderTypechange">
                  <el-radio-button label="">全部</el-radio-button>
                  <el-radio-button label="活动订单">活动订单</el-radio-button>
                  <el-radio-button label="线索订单">线索订单</el-radio-button>
                  <el-radio-button label="专家订单">专家订单</el-radio-button>
                  <el-radio-button label="报告订单">报告订单</el-radio-button>
                  <el-radio-button label="人才订单">人才订单</el-radio-button>
                  <!-- <el-radio-button label="艾媒订单">艾媒订单</el-radio-button> -->
                  <el-radio-button label="诊断订单">诊断订单</el-radio-button>
                  <!-- <el-radio-button label="混合订单">混合订单</el-radio-button> -->
                  <el-radio-button label="个人会员订单">个人会员订单</el-radio-button>
                  <el-radio-button label="企业会员订单">企业会员订单</el-radio-button>
                </el-radio-group>
                <!-- <el-radio-group v-model="queryInfo.orderMember" @change="orderTypechange">
                  <el-radio-button label="会员订单">会员订单</el-radio-button>
                </el-radio-group> -->
              </el-form-item>
              <el-form-item label="订单进度">
                <el-radio-group
                  v-model="queryInfo.orderSchedule"
                  @change="orderTypechange('orderSchedule')"
                >
                  <el-radio-button label="">全部</el-radio-button>
                  <el-radio-button :label="1">执行中</el-radio-button>
                  <el-radio-button :label="2">已完结</el-radio-button>
                  <el-radio-button :label="3">已退款</el-radio-button>
                </el-radio-group>
                <el-radio-group v-model="queryInfo.state" @change="orderTypechange">
                  <el-radio-button label="1">未支付</el-radio-button>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="订单渠道">
                <el-radio-group v-model="queryInfo.orderClass" @change="changeSearchangeSearch">
                  <el-radio-button
                    v-for="(item, index) in firstLevel"
                    :key="item.codeId"
                    :label="index"
                    >{{ item.codeName }}</el-radio-button
                  >
                </el-radio-group>
              </el-form-item>
              <div class="second_level" v-if="queryInfo.orderClass == 1">
                <el-radio-group v-model="queryInfo.partnerName" @change="changeSearchangeSearch">
                  <el-radio-button
                    v-for="item in secondLevel"
                    :key="item.codeId"
                    :label="item.codeName"
                  ></el-radio-button>
                </el-radio-group>
              </div>
              <div
                style="border-bottom: 1px dashed #efefef; padding-top: 20px; margin-bottom: 20px"
              ></div>
              <el-form-item label=" " label-width="0px">
                <div class="input-with">
                  <div style="display: flex">
                    <el-button type="primary" @click="addorder">新增订单</el-button>
                    <el-button @click="exportOrder">批量导出</el-button>
                    <div class="ckeckbox_text">
                      <el-checkbox
                        :indeterminate="isIndeterminate"
                        v-model="checkAll"
                        @change="handleCheckAllChange"
                        >全选</el-checkbox
                      >
                    </div>
                  </div>
                  <div>
                    <el-input
                      placeholder="请输入订单编号、付款公司、付款人"
                      v-model="queryInfo.keys"
                      class="input-with-select"
                    >
                      <el-button class="seach" slot="append" @click="changeSearchangeSearch"
                        >搜索</el-button
                      >
                    </el-input>
                  </div>
                </div>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </div>
      <div class="tab_txt" v-loading="loading">
        <div style="display: flex; align-items: center; padding-bottom: 10px">
          <div style="padding-right: 50px">
            <span>当前条件下共查出{{ total }}条订单</span>
          </div>
          <div
            v-for="(item, index) in orderList"
            :key="index"
            style="display: flex; align-items: center; margin-right: 30px"
          >
            {{ item.name }}
            <div class="main">
              <span
                class="arrUp"
                @click="clickUp1(item)"
                :style="{ 'border-bottom-color': item.color1 }"
              ></span>
              <span
                class="arrDown"
                @click="clickDown1(item)"
                :style="{ 'border-top-color': item.color2 }"
              ></span>
            </div>
          </div>
        </div>
        <div class="storeCenter_item" v-for="(item, index) in listData" :key="index">
          <div
            class="item_checkbox"
            v-if="item.orderType !== '混合订单' && item.orderType !== '艾媒订单'"
          >
            <el-checkbox
              @change="latechange($event, item)"
              v-model="item.checked1"
              label=""
            ></el-checkbox>
          </div>
          <div
            class="storeCenter_item_top"
            v-if="item.orderType !== '混合订单' && item.orderType !== '艾媒订单'"
          >
            <div class="storeCenter_item_top_left">
              <div class="storeCenter_item_top_left_flex">
                <div class="storeCenter_item_top_left_order">
                  <div class="order_name am" v-if="item.orderType == '艾媒订单'">
                    {{ item.orderType }}
                  </div>
                  <div class="order_name rc" v-if="item.orderType == '人才订单'">
                    {{ item.orderType }}
                  </div>
                  <div class="order_name xs" v-if="item.orderType == '线索订单'">
                    {{ item.orderType }}
                  </div>
                  <div class="order_name zj" v-if="item.orderType == '专家订单'">
                    {{ item.orderType }}
                  </div>
                  <div class="order_name hd" v-if="item.orderType == '活动订单'">
                    {{ item.orderType }}
                  </div>
                  <div class="order_name zd" v-if="item.orderType == '诊断订单'">
                    {{ item.orderType }}
                  </div>
                  <div class="order_name zd" v-if="item.orderType == '混合订单'">
                    {{ item.orderType }}
                  </div>
                  <div class="order_name hy" v-if="item.orderType == '个人会员订单'">
                    {{ item.orderType }}
                  </div>
                  <div class="order_name hy" v-if="item.orderType == '企业会员订单'">
                    {{ item.orderType }}
                  </div>
                  <div class="order_name bg" v-if="item.orderType == '报告订单'">
                    {{ item.orderType }}
                  </div>

                  <div class="order_num">{{ item.orderNumber }}</div>
                  <div class="state_one" v-if="item.state == 1">
                    <span>未支付</span>
                  </div>
                  <div class="state_two" v-if="item.state == 2">
                    <span v-if="item.activePrice">￥{{ item.activePrice }}</span>
                  </div>
                </div>
                <div class="storeCenter_item_top_left_order" style="margin-top: 10px">
                  <div class="compny_name">订单名称：</div>
                  <div>{{ item.orderName }}</div>
                </div>
                <div class="storeCenter_item_top_left_order" style="padding: 14px 0px">
                  <div class="compny_name">付款人/企业：</div>
                  <div v-if="item.paySysName">{{ item.paySysName }}</div>
                  <div v-if="item.paySysName && item.companyName">/</div>
                  <div>
                    {{ item.companyName }}
                  </div>
                </div>
                <div class="storeCenter_item_top_left_order">
                  <div class="storeCenter_item_top_left_order">
                    <div class="compny_name">创建时间：</div>
                    <div>{{ item.createTime }}</div>
                  </div>
                  <div class="item_orderClass" v-if="item.orderClass == 1">
                    <img
                      src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/Group 3973.png"
                      alt=""
                    />
                    <span>智参订单</span>
                  </div>
                  <div class="item_orderClass" v-if="item.orderClass == 2">
                    <img
                      src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/Group 3976.png"
                      alt=""
                    />
                    <span>智选订单</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="storeCenter_item_top_center">
              <el-steps
                :space="item.orderScheduleListResultList.length == 3 ? 360 : 200"
                :style="
                  item.orderScheduleListResultList.length == 3
                    ? 'position: relative;right: 50px;'
                    : ''
                "
                align-center
                :active="item.schedule"
              >
                <el-step
                  icon=""
                  v-for="(el, indexs) in item.orderScheduleListResultList"
                  :key="indexs"
                >
                  <template slot="title">
                    <span class="codeName_size">{{ el.codeName }}</span>
                    <img
                      class="file_pdf"
                      @click="openPdf(el.scheduleFile)"
                      v-if="el.scheduleFile"
                      src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/24gf-folderOpen.png"
                      alt=""
                    />
                    <el-tooltip
                      v-if="el.refundReason"
                      class="item"
                      effect="dark"
                      :content="el.refundReason"
                      placement="top-start"
                    >
                      <i style="color: #ff8686; margin-left: 5px" class="el-icon-warning"></i>
                    </el-tooltip>
                  </template>
                  <template slot="description">
                    <span>{{ el.createTime }}</span>
                  </template>
                </el-step>
              </el-steps>
            </div>

            <div class="storeCenter_item_top_right">
              <div class="operation_area">
                <el-button
                  v-if="item.orderType !== '艾媒订单' && item.orderType !== '人才订单'"
                  type="danger"
                  @click="refund(item)"
                  plain
                  size="mini"
                  >退款</el-button
                >

                <span class="edit" @click="editinfo(item)">详情</span>
              </div>
            </div>

            <div class="Relevancy" v-if="item.enterpriseCategory == '传统企业'">
              <span>{{ item.enterpriseCategory }}</span>
            </div>
            <div class="Relevancyblue" v-else-if="item.enterpriseCategory != null">
              <span>{{ item.enterpriseCategory }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="new_page" v-if="listData.length">
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <el-dialog
      title="新增订单"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="订单类型:" prop="orderType">
          <el-radio-group v-model="ruleForm.orderType">
            <el-radio
              style="padding-top: 15px"
              @change="getCodes(item)"
              v-for="(item, index) in orderTypeList"
              :key="index"
              :label="item.val"
              >{{ item.val }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item label="订单渠道:" prop="orderClass">
          <el-radio-group v-model="ruleForm.orderClass" @change="orderClassChange">
            <el-radio :label="1">智参订单</el-radio>
            <el-radio :label="2">智选订单 </el-radio>
          </el-radio-group>
        </el-form-item>
        <div class="width_input">
          <el-form-item label="订单名称:" prop="orderName">
            <div class="orderName_flex">
              <el-input v-model="ruleForm.orderName" placeholder="请输入订单名称"></el-input>
              <div v-if="!ruleForm.orderNumber" class="po_ordernum">
                <span>订单编号展示区域</span>
              </div>
              <div v-else class="po_ordernum">
                <span>{{ ruleForm.orderNumber }}</span>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="订单进度:" prop="orderSchedules">
            <div class="select_input">
              <el-select v-model="ruleForm.orderSchedules" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.codeName"
                  :label="item.codeName"
                  :value="item.codeName"
                >
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="付款人" prop="paySysName">
            <el-input v-model="ruleForm.paySysName" placeholder="请输入付款人"/>
          </el-form-item>
          <el-form-item label="付款企业:" prop="companyId">
            <!-- <el-input v-model="ruleForm.companyId" placeholder="请输入企业名称"></el-input> -->
            <el-autocomplete
              v-model="ruleForm.companyname"
              :fetch-suggestions="
                (res, cb) => {
                  return querySearchAsyncapts(res, cb)
                }
              "
              placeholder="请搜索付款企业"
              @select="
                (res) => {
                  return handleSelectcapts(res)
                }
              "
            >
              <template slot-scope="{ item }">
                <div class="name">
                  <!-- <img
                      :src="item.coverImage"
                      style="border-radius: 28px"
                      width="28px"
                      height="28px"
                      alt=""
                    /> -->
                  <span class="addr" style="position: relative; top: -8px; left: 5px">{{
                    item.companyFullName
                  }}</span>
                </div>
              </template>
            </el-autocomplete>
          </el-form-item>
          <el-form-item label="关联合作机构:" prop="resource">
            <!-- <el-input :disabled="ruleForm.orderClass==2" v-model="ruleForm.input" placeholder="请输入合作机构名称"></el-input> -->
            <el-autocomplete
              :disabled="ruleForm.orderClass == 2"
              v-model="ruleForm.partnerName"
              :fetch-suggestions="
                (res, cb) => {
                  return querySearchAsyncapts1(res, cb)
                }
              "
              placeholder="请搜索关联合作机构"
              @select="
                (res) => {
                  return handleSelectcapts1(res)
                }
              "
            >
              <template slot-scope="{ item }">
                <div class="name">
                  <img
                    :src="item.coverImage"
                    style="border-radius: 28px"
                    width="28px"
                    height="28px"
                    alt=""
                  />
                  <span class="addr" style="position: relative; top: -8px; left: 5px">{{
                    item.partnerName
                  }}</span>
                </div>
              </template>
            </el-autocomplete>
          </el-form-item>

          <el-form-item label="付款金额:" prop="activePrice">
            <div class="select_input">
              <el-input v-model="ruleForm.activePrice" placeholder="请输入金额"></el-input> 元
            </div>
          </el-form-item>
          <el-form-item label="上传附件:" prop="desc">
            <div class="icon_flex">
              <i style="font-size: 20px; margin-right: 10px" class="el-icon-paperclip"></i>
              <el-upload
                action
                class="upload-demo"
                ref="upload"
                :show-file-list="false"
                :before-upload="beforeUpload"
                :http-request="upload"
              >
                <el-button slot="trigger" size="mini" plain>上传附件</el-button>
              </el-upload>
              <div class="scheduleFile">
                <span>{{ this.ruleForm.scheduleFile }}</span>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="进度描述:" prop="scheduleDescribe">
            <el-input type="textarea" v-model="ruleForm.scheduleDescribe"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="clickSave">添加</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="详情"
      :before-close="handleCloseinfo"
      :visible.sync="dialogVisibleinfo"
      width="50%"
    >
      <div class="orderinfo_div">
        <div class="storeCenter_item_top_left_order">
          <div class="order_name am" v-if="orderinfo.orderType == '艾媒订单'">
            {{ orderinfo.orderType }}
          </div>
          <div class="order_name rc" v-if="orderinfo.orderType == '人才订单'">
            {{ orderinfo.orderType }}
          </div>
          <div class="order_name xs" v-if="orderinfo.orderType == '线索订单'">
            {{ orderinfo.orderType }}
          </div>
          <div class="order_name zj" v-if="orderinfo.orderType == '专家订单'">
            {{ orderinfo.orderType }}
          </div>
          <div class="order_name hd" v-if="orderinfo.orderType == '活动订单'">
            {{ orderinfo.orderType }}
          </div>
          <div class="order_name zd" v-if="orderinfo.orderType == '诊断订单'">
            {{ orderinfo.orderType }}
          </div>
          <div
            class="order_name hy"
            v-if="orderinfo.orderType == '个人会员订单' || orderinfo.orderType == '企业会员订单'"
          >
            {{ orderinfo.orderType }}
          </div>

          <div class="order_name bg" v-if="orderinfo.orderType == '报告订单'">
            {{ orderinfo.orderType }}
          </div>

          <div class="order_num">{{ orderinfo.orderNumber }}</div>
        </div>
        <div class="storeCenter_item_top_left_order" style="margin-top: 10px">
          <div class="compny_name">订单名称：</div>
          <div>{{ orderinfo.orderName }}</div>
        </div>
        <div class="storeCenter_item_top_left_order" style="padding: 8px 0px">
          <div class="compny_name" style="color: #999999">付款人/企业：</div>
          <div v-if="orderinfo.realName">{{ orderinfo.realName }}/</div>
          <div>
            {{ orderinfo.companyName }}
          </div>
        </div>
        <div class="storeCenter_item_top_left_order">
          <div class="storeCenter_item_top_left_order">
            <div class="compny_name">创建时间：</div>
            <div>{{ orderinfo.createTime }}</div>
          </div>
          <div class="item_orderClass" v-if="orderinfo.orderClass == 1">
            <img
              src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/Group 3973.png"
              alt=""
            />
            <span>智参订单</span>
          </div>
          <div class="item_orderClass" v-if="orderinfo.orderClass == 2">
            <img
              src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/Group 3976.png"
              alt=""
            />
            <span>智选订单</span>
          </div>
        </div>
        <div v-if="orderinfo.notTermination != 2">
          <div
            class="termination"
            @click="termination"
            v-if="orderinfo.orderType == '艾媒订单' || orderinfo.orderType == '人才订单'"
          >
            <i class="el-icon-circle-close"></i>
            订单终止
          </div>
        </div>
      </div>
      <el-button type="primary" v-if="orderinfo.notTermination != 2" @click="addschedule"
        >+添加进度</el-button
      >
      <!-- <el-button plain >+配置</el-button> -->
      <el-table
        ref="multipleTables"
        v-loading="loading"
        :data="ScheduleList"
        stripe
        width="100%"
        class="in_table"
        :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
      >
        <el-table-column label="订单进度" prop="orderSchedule" align="center">
          <template v-slot="{ row }">
            <span v-if="row.orderSchedule == '订单终止'">
              <i style="color: #ff5a5a" class="el-icon-circle-close"></i>
              {{ row.orderSchedule }}</span
            >
            <span v-else>{{ row.orderSchedule }}</span>
          </template>
        </el-table-column>

        <el-table-column label="进度描述" prop="" align="center">
          <template v-slot="{ row }">
            <span>{{ row.scheduleDescribe }}</span>
          </template>
        </el-table-column>
        <el-table-column label="进度附件" prop="scheduleFile" align="center"></el-table-column>

        <el-table-column label="添加人" prop="userName" align="center"></el-table-column>
        <el-table-column
          label="添加时间"
          sortable
          prop="createTime"
          align="center"
        ></el-table-column>
      </el-table>
      <div class="new_page">
        <el-pagination
          :current-page="orderSchedule.pageNum"
          :page-sizes="[10, 30, 50]"
          :page-size="orderSchedule.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totaldins"
          @size-change="handleSize"
          @current-change="handleCurrent"
        />
      </div>
    </el-dialog>
    <el-dialog title="添加进度" :visible.sync="dialogVisiblejd" width="50%">
      <div class="orderinfo_div">
        <div class="storeCenter_item_top_left_order">
          <div class="order_name am" v-if="orderinfo.orderType == '艾媒订单'">
            {{ orderinfo.orderType }}
          </div>
          <div class="order_name rc" v-if="orderinfo.orderType == '人才订单'">
            {{ orderinfo.orderType }}
          </div>
          <div class="order_name xs" v-if="orderinfo.orderType == '线索订单'">
            {{ orderinfo.orderType }}
          </div>
          <div class="order_name zj" v-if="orderinfo.orderType == '专家订单'">
            {{ orderinfo.orderType }}
          </div>
          <div class="order_name hd" v-if="orderinfo.orderType == '活动订单'">
            {{ orderinfo.orderType }}
          </div>
          <div class="order_name zd" v-if="orderinfo.orderType == '诊断订单'">
            {{ orderinfo.orderType }}
          </div>
          <div class="order_name zd" v-if="orderinfo.orderType == '混合订单'">
            {{ orderinfo.orderType }}
          </div>
          <div
            class="order_name hy"
            v-if="orderinfo.orderType == '个人会员订单' || orderinfo.orderType == '企业会员订单'"
          >
            {{ orderinfo.orderType }}
          </div>
          <div class="order_name bg" v-if="orderinfo.orderType == '报告订单'">
            {{ orderinfo.orderType }}
          </div>

          <div class="order_num">{{ orderinfo.orderNumber }}</div>
        </div>
        <div class="storeCenter_item_top_left_order" style="margin-top: 10px">
          <div class="compny_name">订单名称：</div>
          <div>{{ orderinfo.orderName }}</div>
        </div>
        <div class="storeCenter_item_top_left_order" style="padding: 8px 0px">
          <div class="compny_name">付款人/企业：</div>
          <div v-if="orderinfo.realName">{{ orderinfo.realName }}/</div>
          <div>
            {{ orderinfo.companyName }}
          </div>
        </div>
        <div class="storeCenter_item_top_left_order">
          <div class="storeCenter_item_top_left_order">
            <div class="compny_name">创建时间：</div>
            <div>{{ orderinfo.createTime }}</div>
          </div>
          <div class="item_orderClass" v-if="orderinfo.orderClass == 1">
            <img
              src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/Group 3973.png"
              alt=""
            />
            <span>智参订单</span>
          </div>
          <div class="item_orderClass" v-if="orderinfo.orderClass == 2">
            <img
              src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/Group 3976.png"
              alt=""
            />
            <span>智选订单</span>
          </div>
        </div>
      </div>
      <el-form
        :model="param"
        :rules="rules"
        ref="paramForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <div class="width_input">
          <el-form-item label="订单进度:" prop="orderSchedule">
            <div class="select_input">
              <el-select v-model="param.orderSchedule" placeholder="请选择">
                <el-option
                  v-for="item in options"
                  :key="item.codeName"
                  :label="item.codeName"
                  :value="item.codeName"
                >
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="付款企业:" prop="">
            <el-input
              disabled
              v-model="orderinfo.companyName"
              placeholder="请输入企业名称"
            ></el-input>
          </el-form-item>
          <el-form-item v-if="orderinfo.orderClass == 1" label="关联合作机构:" prop="partnerName">
            <el-input
              disabled
              v-model="orderinfo.partnerName"
              placeholder="请输入合作机构名称"
            ></el-input>
          </el-form-item>

          <el-form-item label="上传附件:" prop="desc">
            <div class="icon_flex">
              <i style="font-size: 20px; margin-right: 10px" class="el-icon-paperclip"></i>
              <el-upload
                action
                class="upload-demo"
                ref="upload"
                :show-file-list="false"
                :before-upload="beforeUpload"
                :http-request="upload"
              >
                <el-button slot="trigger" size="mini" plain>上传附件</el-button>
              </el-upload>
              <div class="scheduleFile">
                <span>{{ this.param.scheduleFile }}</span>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="进度描述:" prop="scheduleDescribe">
            <el-input type="textarea" v-model="param.scheduleDescribe"></el-input>
          </el-form-item>
        </div>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCloses">取 消</el-button>
        <el-button type="primary" @click="scheduleSave">添加</el-button>
      </span>
    </el-dialog>
    <el-dialog title="退款" :visible.sync="dialogrefund" width="30%" :before-close="refundClose">
      <div class="refuse">
        <el-form
          :model="refuseObj"
          :rules="refuse"
          ref="refuseForm"
          label-width="60px"
          class="demo-ruleForm"
        >
          <el-form-item label="理由" prop="refundReason">
            <el-input
              type="textarea"
              :rows="10"
              placeholder="请输入退款理由"
              v-model="refuseObj.refundReason"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="refundClose">取 消</el-button>
        <el-button type="primary" @click="submitrefund">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="终止项目" :visible.sync="dialogVisibleproject" width="50%">
      <div class="orderinfo_div">
        <div class="storeCenter_item_top_left_order">
          <div class="order_name am" v-if="orderinfo.orderType == '艾媒订单'">
            {{ orderinfo.orderType }}
          </div>
          <div class="order_name rc" v-if="orderinfo.orderType == '人才订单'">
            {{ orderinfo.orderType }}
          </div>
          <div class="order_name xs" v-if="orderinfo.orderType == '线索订单'">
            {{ orderinfo.orderType }}
          </div>
          <div class="order_name zj" v-if="orderinfo.orderType == '专家订单'">
            {{ orderinfo.orderType }}
          </div>
          <div class="order_name hd" v-if="orderinfo.orderType == '活动订单'">
            {{ orderinfo.orderType }}
          </div>
          <div class="order_name zd" v-if="orderinfo.orderType == '诊断订单'">
            {{ orderinfo.orderType }}
          </div>
          <div class="order_name zd" v-if="orderinfo.orderType == '混合订单'">
            {{ orderinfo.orderType }}
          </div>
          <div
            class="order_name hy"
            v-if="orderinfo.orderType == '个人会员订单' || orderinfo.orderType == '企业会员订单'"
          >
            {{ orderinfo.orderType }}
          </div>
          <div class="order_name bg" v-if="orderinfo.orderType == '报告订单'">
            {{ orderinfo.orderType }}
          </div>

          <div class="order_num">{{ orderinfo.orderNumber }}</div>
        </div>
        <div class="storeCenter_item_top_left_order" style="margin-top: 10px">
          <div class="compny_name">订单名称：</div>
          <div>{{ orderinfo.orderName }}</div>
        </div>
        <div class="storeCenter_item_top_left_order" style="padding: 8px 0px">
          <div class="compny_name">付款人/企业：</div>
          <div v-if="orderinfo.realName">{{ orderinfo.realName }}/</div>
          <div>
            {{ orderinfo.companyName }}
          </div>
        </div>
        <div class="storeCenter_item_top_left_order">
          <div class="storeCenter_item_top_left_order">
            <div class="compny_name">创建时间：</div>
            <div>{{ orderinfo.createTime }}</div>
          </div>
          <div class="item_orderClass" v-if="orderinfo.orderClass == 1">
            <img
              src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/Group 3973.png"
              alt=""
            />
            <span>智参订单</span>
          </div>
          <div class="item_orderClass" v-if="orderinfo.orderClass == 2">
            <img
              src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/Group 3976.png"
              alt=""
            />
            <span>智选订单</span>
          </div>
        </div>
      </div>
      <el-form
        :model="orderNot"
        :rules="orderNotrules"
        ref="orderNotForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <div class="width_input">
          <el-form-item label="终止理由:" prop="refundReason">
            <el-input type="textarea" v-model="orderNot.refundReason"></el-input>
          </el-form-item>
          <el-form-item label="上传附件:" prop="desc">
            <div class="icon_flex">
              <i style="font-size: 20px; margin-right: 10px" class="el-icon-paperclip"></i>
              <el-upload
                action
                class="upload-demo"
                ref="upload"
                :show-file-list="false"
                :before-upload="beforeUploads"
                :http-request="uploads"
              >
                <el-button slot="trigger" size="mini" plain>上传附件</el-button>
              </el-upload>
              <div class="scheduleFile">
                <span>{{ this.orderNot.scheduleFile }}</span>
              </div>
            </div>
          </el-form-item>
        </div>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="proClose">取 消</el-button>
        <el-button type="primary" @click="orderNotSave">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  listPage,
  save,
  listById,
  OrderScheduleSave,
  orderScheduleListPage,
  orderCompanyResults,
  getCode,
  orderNumber,
  orderNotTermination,
  refund,
  getCodeMaster,
  exportOrder
} from '@/api/order'
import { apiUpload } from '../../api/content.js'
import { getPartners } from '@/api/datement'
const defaultQueryInfo = Object.freeze({
  pageNum: 1,
  pageSize: 10,
  userSchema: 1, //合作伙伴编号
  keys: '', //订单编号/付款公司/付款人
  orderClass: 0, //订单渠道；1是代表智参订单，2是智选订单
  orderMember: '', //会员订单
  orderSchedule: '', //订单进度 1->执行中;2-已完结;3->已终止
  orderType: '', //订单类型；活动订单，专家订单,报告订单等(除会员订单)
  state: '', //未支付->1
  orders: '1', //排序 默认传1 2->创建时间升序;3->创建时间降序;4->支付金额升序;5->支付金额降序
  partnerName: '' // 智参总后台
})
export default {
  name: 'order',

  data() {
    return {
      ids: [],
      isIndeterminate: false,
      checkAll: false,
      idsList: [],
      queryInfo: { ...defaultQueryInfo },
      listData: [],
      // 遮罩层
      loading: false,
      // 总条数
      total: 0,
      dialogVisible: false, //新增订单
      dialogVisibleinfo: false, //详情
      dialogVisiblejd: false, //订单进度
      dialogrefund: false, //退款
      dialogVisibleproject: false, //终止项目
      ruleForm: {
        paySysName:'',  //付款人
        activePrice: null, //活动单价
        companyname: '', //付款单位
        companyId: '', //付款企业编号
        orderClass: '', //订单分类；1是代表智参订单，2是智选订单
        orderName: '', //订单名称
        orderNumber: '', //支付单号
        orderSchedules: '', //订单进度
        orderType: '', //订单类型；活动订单，专家订单等
        partnerId: '', //合作伙伴编号
        scheduleDescribe: '', //进度描述
        scheduleFile: '', //进度附件
        type: '', //订单类型管理code值
        partnerName: '' // 智参总后台
      },
      //订单进度新增
      param: {
        orderId: '', //订单编号
        orderSchedule: '', //订单进度
        scheduleDescribe: '', //进度描述
        scheduleFile: '' //进度附件
      },
      orderSchedule: {
        orderId: '',
        pageNum: 1,
        pageSize: 10,
        orders: 1
      },
      //终止订单项目
      orderNot: {
        id: '', //	订单编号
        notTermination: 2, //订单终止(用于人才订单、艾媒订单) 默认为1 1->执行;2->终止
        refundReason: '', //理由
        scheduleFile: '' //附件
      },
      totaldins: 0,
      orderinfo: {}, //订单详情
      ScheduleList: [], //订单的订单进度一览
      //订单终止理由
      orderNotrules: {
        refundReason: [{ required: true, message: '请输入终止里由', trigger: 'blur' }]
      },
      rules: {
        orderType: [{ required: true, message: '请勾选订单类型', trigger: 'blur' }],
        orderClass: [{ required: true, message: '请勾选订单渠道', trigger: 'blur' }],
        orderName: [{ required: true, message: '请输入订单名称', trigger: 'blur' }],
        orderSchedules: [{ required: true, message: '请选择订单进度', trigger: 'blur' }],
        orderSchedule: [{ required: true, message: '请选择订单进度', trigger: 'blur' }],
        companyName: [{ required: true, message: '请输入付款企业', trigger: 'blur' }],
        companyId: [{ required: true, message: '请输入付款企业', trigger: 'blur' }],
        // activePrice: [{ required: true, message: '请输入付款金额' }],
        paySysName:[{ required: true, message: '请输入付款人', trigger: 'blur' }],
        scheduleDescribe: [{ required: true, message: '请输入进度描述', trigger: 'change' }]
      },
      options: [], //各种订单的订单进度
      orderTypeList: [
        {
          id: '004',
          val: '艾媒订单'
        },
        {
          id: '005',
          val: '人才订单'
        },
        {
          id: '006',
          val: '线索订单'
        },
        {
          id: '007',
          val: '专家订单'
        },
        {
          id: '008',
          val: '活动订单'
        },
        {
          id: '009',
          val: '诊断订单'
        },
        {
          id: '011',
          val: '报告订单'
        },
        {
          id: '010',
          val: '个人会员订单'
        },
        {
          id: '010',
          val: '企业会员订单'
        },
        {
          id: '013',
          val: '混合订单'
        }
      ],

      orderList: [
        {
          name: '按创建时间',
          color1: '',
          color2: '#C6C6C6',
          asc: 2,
          desc: 3
        },
        {
          name: '按支付金额',
          color1: '',
          color2: '#C6C6C6',
          asc: 4,
          desc: 5
        }
      ], //排序
      refuseObj: {
        orderNumber: '', //订单流水号
        refundReason: '' //理由
      },
      refuse: {
        refundReason: [{ required: true, message: '请输入退款理由', trigger: 'blur' }]
      },
      firstLevel: [],
      secondLevel: []
    }
  },
  created() {
    this.search()
    this.getCodeMasterList()
  },

  methods: {
    // 选择订单渠道的时候需要把付款企业清空
    orderClassChange(val) {
      this.ruleForm.companyId = ''
      this.ruleForm.companyname = ''
    },
    // 全选
    handleCheckAllChange(val) {
      if (val) {
        this.listData.forEach((item) => {
          this.ids.push(item.id)
          this.idsList.push(item)
          this.$set(item, 'checked1', true)
        })
      } else {
        this.listData.forEach((item) => {
          this.ids = []
          this.idsList = []
          this.$set(item, 'checked1', false)
        })
      }
      this.isIndeterminate = val
    },
    // 多选框选中数据
    latechange(bol, row) {
      const index = this.ids.indexOf(row.id)
      if (index == -1) {
        this.ids.push(row.id)
        this.idsList.push(row)
      } else {
        this.ids.splice(index, 1)
        this.idsList.splice(index, 1)
      }
    },
    //批量导出
    async exportOrder() {  
      if(!this.ids.length){
        this.$message.error('请选择需要导出的信息!')
        return
      }
      const query = {
        ...this.queryInfo,
        ids: this.ids
      }
      const res = await exportOrder(query)
      if (res.data) {
        let blob = new Blob([res.data])
        let href = window.URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
        const a = document.createElement('a') //创建a标签
        a.style.display = 'none'
        console.log(href)
        a.href = href // 指定下载'链接
        let nowdata = new Date()
        let year = nowdata.getFullYear()
        let month = nowdata.getMonth() + 1
        let date = nowdata.getDate()
        let nowtime = year + '-'
        if (month < 10) {
          nowtime += '0'
        }
        nowtime += month + '-'
        if (date < 10) {
          nowtime += '0'
        }
        nowtime += date
        a.download = `订单数据${nowtime}.xlsx` //指定下载文件名 //指定下载文件名
        // a.download = "test.png";
        a.click() //触发下载
        window.URL.revokeObjectURL(a.href) //释放URL对象
        if (document.body.contains(a)) {
          document.body.removeChild(a) //释放标签
        }
        this.search()
        this.isIndeterminate = false
        this.checkAll = false
      } else {
        this.$message.error('下载失败!')
      }
    },
    //订单类型选择
    orderTypechange(val) {
      // if (!val) {
      //   this.queryInfo.orderMember = ''
      //   this.queryInfo.state = ''
      // }  if (val == '会员订单') {
      //   this.queryInfo.orderType = null
      // } else{
      //   this.queryInfo.orderMember = ''
      // }
      if (val == '1') {
        this.queryInfo.orderSchedule = null
      } else {
        this.queryInfo.state = ''
      }
      this.queryInfo.pageNum = 1
      this.search()
    },
    //查询订单
    async search() {
      this.loading = true
      if (this.queryInfo.orderClass != 1) this.queryInfo.partnerName = ''
      const res = await listPage(this.queryInfo)
      if (res.data.resultCode == 200) {
        // 删除混合订单/艾媒订单
        this.listData = res.data.data.list.filter((item) => {
          return item.orderType !== '混合订单' && item.orderType !== '艾媒订单'
        })

        this.total = res.data.data.total
        this.loading = false
        this.listData.forEach((item) => {
          this.$set(item, 'checked1', false)
          this.$set(item, 'schedule', 1)
          if (item.orderScheduleListResultList.length) {
            item.orderScheduleListResultList.forEach((el, index) => {
              if (item.orderScheduleListResultList[index].createTime) {
                // this.$set(item, 'schedule', index+1)
                item.schedule = index + 1
              }
            })
            if (item.notTermination == 2) {
              item.orderScheduleListResultList[item.schedule - 1].refundReason = item.refundReason
            }
          }
        })
      }
    },
    // 获取单选框列表
    async getCodeMasterList() {
      const { data: res } = await getCodeMaster({ codeType: '027' })
      this.firstLevel = res.data
      this.secondLevel = res.data.flatMap((item) => item.childNode)
    },
    //新增订单查询订单进度
    async getCodes(item) {
      this.ruleForm.type = item.id
      const query = {
        codeType: item.id
      }
      const res = await getCode(query)
      if (res.data.resultCode == 200) {
        this.options = res.data.data
        this.orderNumber(item.val) //订单编号
      }
    },
    //搜索合作机构
    async querySearchAsyncapts1(queryString, cb) {
      let specilist1 = []
      if (this.ruleForm.partnerName) {
        const res = await getPartners({
          keyWord: this.ruleForm.partnerName
        })
        if (res.data.resultCode == 200) {
          specilist1 = res.data.data
          cb(specilist1)
        }
      } else {
        cb(specilist1)
      }
    },

    //选择合作伙伴
    handleSelectcapts1(item) {
      this.ruleForm.partnerName = item.partnerName
      this.ruleForm.partnerId = item.id
    },
    //搜索企业
    async querySearchAsyncapts(queryString, cb) {
      let specilist1 = []
      const res = await orderCompanyResults({
        companyName: this.ruleForm.companyname,
        customerType: this.ruleForm.orderClass
      })
      if (res.data.resultCode === 200) {
        // console.log("搜索企业",res.data.data[0].partnerId)
        this.ruleForm.partnerId = res.data.data[0].partnerId
        specilist1 = res.data.data
        cb(specilist1)
      } else {
        this.$message.warning(res.message)
      }
      // if (this.ruleForm.orderClass == 1) {
      //   const res = await orderCompanyResults({
      //     companyName: this.ruleForm.companyname,
      //     customerType: this.ruleForm.orderClass
      //   })
      //   if (res.data.resultCode === 200) {
      //     // console.log("搜索企业",res.data.data[0].partnerId)
      //     this.ruleForm.partnerId = res.data.data[0].partnerId
      //     specilist1 = res.data.data
      //     cb(specilist1)
      //   }
      //   // if (this.ruleForm.companyname) {
      //   //   const res = await orderCompanyResults({
      //   //     companyName: this.ruleForm.companyname,
      //   //     customerType: this.ruleForm.orderClass
      //   //   })
      //   //   if (res.data.resultCode == 200) {
      //   //     specilist1 = res.data.data
      //   //     cb(specilist1)
      //   //   }
      //   // } else {
      //   //   cb(specilist1)
      //   // }
      // } else {
      //   this.$message.warning('请先选择订单渠道！')
      // }
    },
    // 保存
    clickSave() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          // console.log("添加订单",this.ruleForm)
          save(this.ruleForm).then((response) => {
            if (response.data.resultCode === 200) {
              this.search()
              this.$message.success('新增成功')
              this.dialogVisible = false
            } else {
              this.$message.error(response.data.message)
            }
          })
        }
      })
    },
    //取消添加进度
    handleCloses() {
      this.dialogVisiblejd = false
    },
    //进度添加
    scheduleSave() {
      this.$refs['paramForm'].validate((valid) => {
        if (valid) {
          OrderScheduleSave(this.param).then((response) => {
            if (response.data.resultCode == 200) {
              this.orderScheduleListPage()
              this.$message.success('新增成功')
              this.dialogVisiblejd = false
            } else {
              this.$message.error(response.data.message)
            }
          })
        }
      })
    },
    //订单终止
    orderNotSave() {
      this.orderNot.id = this.orderinfo.id
      this.$refs['orderNotForm'].validate((valid) => {
        if (valid) {
          orderNotTermination(this.orderNot).then((response) => {
            if (response.data.resultCode == 200) {
              this.dialogVisibleproject = false
              this.orderScheduleListPage()
              this.$message.success('终止成功')

              this.listById(this.orderNot.id)
            } else {
              this.$message.error(response.data.message)
            }
          })
        }
      })
    },
    //退款
    submitrefund() {
      this.$refs['refuseForm'].validate((valid) => {
        if (valid) {
          refund(this.refuseObj).then((response) => {
            if (response.data.resultCode == 200) {
              this.search()
              this.$message.success('退款成功')
              this.dialogrefund = false
            } else {
              this.$message.error(response.data.message)
            }
          })
        }
      })
    },
    //终止项目取消
    proClose() {
      this.dialogVisibleproject = false
      this.orderNot = {
        id: '', //	订单编号
        notTermination: 2, //订单终止(用于人才订单、艾媒订单) 默认为1 1->执行;2->终止
        refundReason: '', //理由
        scheduleFile: '' //附件
      }
    },
    //终止订单
    termination() {
      this.dialogVisibleproject = true
      this.orderNot = {
        id: '', //	订单编号
        notTermination: 2, //订单终止(用于人才订单、艾媒订单) 默认为1 1->执行;2->终止
        refundReason: '', //理由
        scheduleFile: '' //附件
      }
    },
    //选择合作伙伴
    handleSelectcapts(item) {
      this.ruleForm.companyId = item.companyId
      this.ruleForm.companyname = item.companyFullName
      this.ruleForm.partnerName = item.partnerName
      this.ruleForm.partnerId = item.id
    },

    //订单编号
    async orderNumber(type) {
      const query = {
        type
      }
      const { data: res } = await orderNumber(query)
      if (res.resultCode == 200) {
        this.ruleForm.orderNumber = res.data
      }
    },
    //升序排序
    clickUp1(item) {
      this.queryInfo.orders = item.asc
      this.search()
      item.color1 = '#0099ff'
      item.color2 = ''
    },
    //降序排序
    clickDown1(item) {
      this.queryInfo.orders = item.desc
      this.search()
      item.color1 = ''
      item.color2 = '#0099ff'
    },
    //新增订单
    addorder() {
      this.dialogVisible = true
      this.ruleForm = {
        activePrice: null, //活动单价
        companyId: '', //付款企业编号
        orderClass: '', //订单分类；1是代表智参订单，2是智选订单
        orderName: '', //订单名称
        orderNumber: '', //支付单号
        orderSchedules: '', //订单进度
        orderType: '', //订单类型；活动订单，专家订单等
        partnerId: '', //合作伙伴编号
        scheduleDescribe: '', //进度描述
        scheduleFile: '' //进度附件
      }
    },
    //取消新增订单弹窗
    handleClose() {
      this.dialogVisible = false
    },
    //上传pdf
    async beforeUpload(file) {
      this.ruleForm.scheduleFile = ''
      this.file = file
      this.filename = file.name
      let reader = new FileReader()
      reader.readAsDataURL(file)

      let that = this
      reader.onload = function () {
        that.fileData = reader.result
      }
      return false
    },
    //上传
    async upload(params) {
      const form = new FormData()
      form.append('file', params.file)

      const res = await apiUpload(form)
      if (res.data.resultCode === 200) {
        this.ruleForm.scheduleFile = res.data.data
        this.param.scheduleFile = res.data.data
      }
    },
    //上传pdf
    async beforeUploads(file) {
      this.ruleForm.scheduleFile = ''
      this.file = file
      this.filename = file.name
      let reader = new FileReader()
      reader.readAsDataURL(file)

      let that = this
      reader.onload = function () {
        that.fileData = reader.result
      }
      return false
    },
    //上传
    async uploads(params) {
      const form = new FormData()
      form.append('file', params.file)

      const res = await apiUpload(form)
      if (res.data.resultCode === 200) {
        this.orderNot.scheduleFile = res.data.data
      }
    },
    //取消详情
    handleCloseinfo() {
      this.dialogVisibleinfo = false
      this.search()
    },
    //详情
    editinfo(item) {
      this.dialogVisibleinfo = true
      this.listById(item.id)
      this.orderSchedule.orderId = item.id
      this.orderScheduleListPage()
    },
    //退款
    refund(item) {
      this.dialogrefund = true
      this.refuseObj.orderNumber = item.orderNumber
    },
    //取消退款弹窗
    refundClose() {
      this.dialogrefund = false
      this.refuseObj = {
        orderNumber: '', //订单流水号
        refundReason: '' //理由
      }
    },
    //订单详情
    async listById(orderId) {
      const query = {
        orderId
      }
      const { data: res } = await listById(query)
      if (res.resultCode == 200) {
        this.orderinfo = res.data
      }
    },
    //订单进度
    async orderScheduleListPage() {
      const { data: res } = await orderScheduleListPage(this.orderSchedule)
      if (res.resultCode == 200) {
        this.ScheduleList = res.data.list
        this.ScheduleList.forEach((el) => {
          if (el.scheduleFile) {
            var index = el.scheduleFile.lastIndexOf('/')
            el.scheduleFile = el.scheduleFile.substring(index + 1, el.scheduleFile.length)
          }
        })
        this.totaldins = res.data.total
      }
    },
    //新增进度
    addschedule() {
      this.dialogVisiblejd = true
      ;(this.param = {
        orderId: this.orderinfo.id, //订单编号
        orderSchedule: '', //订单进度
        scheduleDescribe: '', //进度描述
        scheduleFile: '' //进度附件
      }),
        this.seachcompany()
      this.orderTypeList.forEach(async (el) => {
        if (this.orderinfo.orderType == el.val) {
          // this.getCodes(el)
          const query = {
            codeType: el.id
          }
          const res = await getCode(query)
          if (res.data.resultCode == 200) {
            this.options = res.data.data
          }
        }
      })
    },
    //回显合作机构
    async seachcompany() {
      const res = await orderCompanyResults({
        companyName: this.orderinfo.companyName,
        customerType: this.orderinfo.orderClass
      })
      if (res.data.resultCode == 200) {
        let specilist = res.data.data
        this.orderinfo.partnerName = specilist[0].partnerName
      }
    },
    //查看附件
    openPdf(val) {
      window.open(val)
    },
    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.isIndeterminate = false
      this.checkAll = false
      this.ids = []
      this.idsList = []
      this.search()
      // console.log(`每页 ${val} 条`)
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      this.isIndeterminate = false
      this.checkAll = false
      this.ids = []
      this.idsList = []
      this.search()
      // console.log(`当前页: ${val}`)
    },
    // 分页
    handleSize(val) {
      this.orderSchedule.pageSize = val
      this.isIndeterminate = false
      this.checkAll = false
      this.ids = []
      this.idsList = []
      this.orderScheduleListPage()
      // console.log(`每页 ${val} 条`)
    },
    // 分页
    handleCurrent(val) {
      this.orderSchedule.pageNum = val
      this.isIndeterminate = false
      this.checkAll = false
      this.ids = []
      this.idsList = []
      this.orderScheduleListPage()
      // console.log(`当前页: ${val}`)
    },
    // 搜索
    changeSearchangeSearch() {
      this.queryInfo.pageNum = 1
      this.search()
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep .el-radio-button__inner {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  background: #fff;
  border: 1px solid #fff;
  font-weight: 500;
  border-left: 0;
  color: #606266;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  position: relative;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 12px 20px;
  font-size: 14px;
  border-radius: 0;
}
::v-deep .el-radio-button:first-child .el-radio-button__inner {
  border-left: 1px solid #fff;
  border-radius: 4px 0 0 4px;
  box-shadow: none !important;
}
::v-deep .el-upload-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: none;
}
::v-deep .width_input .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 500px;
}
::v-deep .select_input .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 200px;
}
::v-deep .width_input .el-textarea {
  position: relative;
  display: inline-block;
  width: 500px;
  vertical-align: bottom;
  font-size: 14px;
}

::v-deep .select_input .el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 200px;
}
::v-deep .el-step__title.is-process {
  font-weight: 700;
  color: #c0c4cc;
}
::v-deep .el-step__head.is-process {
  color: #c0c4cc;
  border-color: #c0c4cc;
}
::v-deep .el-step__icon-inner {
  display: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: center;
  font-weight: 700;
  line-height: 1;
  color: inherit;
}
::v-deep .el-step__icon.is-text {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 4px solid;
  border-color: inherit;
}

.user {
  .container {
    height: calc(100vh - 150px);
    overflow: auto;
    &::-webkit-scrollbar {
    width: 3px;
    height: 9px;
  }
  /* 滚动条里面的滑块 */
  &::-webkit-scrollbar-thumb {
    background-color: rgba(144, 147, 153, 0.3);
    border-radius: 20px;
    transition: background-color 0.3s;
  }
  }
  .orderinfo_div {
    padding: 20px;
    margin-bottom: 20px;
    background: #f9f9f9;
    position: relative;
    .termination {
      position: absolute;
      bottom: 15px;
      right: 15px;
      background: #fff;
      padding: 5px;
      border-radius: 5px;
      cursor: pointer;
    }
  }
  .order_top {
    background: #ffffff;
    padding: 20px;
    .order_top_name {
      font-size: 18px;
      font-weight: 550;
      padding-bottom: 20px;
    }
    .order_top_flex {
      display: flex;
      align-items: flex-end;
      position: relative;
      .po_compny {
        position: absolute;
        color: #4e93fb;
        font-size: 18px;
        font-weight: 550;
        left: 140px;
        top: 0px;
      }
      .img_radius {
        width: 90px;
        height: 90px;
        border-radius: 90px;
      }
      .order_top_flex_top {
        display: flex;
        align-items: center;
        .blue_money {
          color: #0066ff;
          font-size: 28px;
          font-weight: 550;
        }
        .hui_money {
          color: #999999;
        }
      }
      .order_top_flex_bottom {
        .hui_money {
          color: #999999;
        }
      }
      .order_top_flex_left {
        padding-left: 20px;
        line-height: 50px;
      }
      .order_top_flex_right {
        padding-left: 80px;
        line-height: 50px;
      }
    }
    .border_left {
      height: 50px;
      border-left: 2px solid #cccccc;
      margin-left: 50px;
      padding-left: 50px;
    }
    .round_order {
      width: 95px;
      height: 95px;
      border: 10px solid #4e93fb;
      border-radius: 95px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .order_num {
        margin-top: 10px;
        font-weight: 550;
        font-size: 20px;
      }
    }
    .round_order_right {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 0px 30px;
      line-height: 40px;
      .order_num {
        margin-top: 10px;
        font-weight: 550;
        font-size: 20px;
      }
      .order_hui {
        margin-top: 10px;
        font-size: 20px;
        color: #9b9a9a;
      }
    }
  }
  .dialog-footer {
    display: flex;
    justify-content: center;
  }
  .width_input {
    .orderName_flex {
      position: relative;
      display: flex;
      align-items: center;
      width: 500px;
      .po_ordernum {
        position: absolute;
        right: 10px;
        background: #edf4ff;
        border-radius: 4px;
        height: 30px;
        width: 140px;
        padding: 0px 5px;
        text-align: center;
        font-size: 12px;
        line-height: 30px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .icon_flex {
    display: flex;
    align-items: center;
    .scheduleFile {
      width: 300px;
      margin-left: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .form_info {
    background: #ffffff;
    padding: 20px 20px 5px 20px;
    margin-top: 20px;
  }

  .tab_txt {
    margin-top: 20px;
    padding: 0px;
    .main {
      display: flex;
      flex-direction: column;
    }

    .arrUp {
      width: 0;
      height: 0;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
      border-bottom: 8px solid #c6c6c6;
      cursor: pointer;
    }

    .arrDown {
      width: 0;
      height: 0;
      margin-top: 2px;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
      border-top: 8px solid #c6c6c6;
      cursor: pointer;
    }
  }

  .span_div {
    font-size: 14px;
    color: #7c7f8e;
    margin-right: 12px;
  }

  .input-with {
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;

    .input-with-select {
      width: 400px;
    }

    .seach {
      background-color: #448aff;
      color: #fff;
      border-radius: 0;
      border: 1px solid #448aff;
    }
  }

  .in_table {
    width: 100%;
    margin-top: 20px;

    .img_url {
      width: 42px;
      height: 42px;
      border-radius: 4px;
    }
  }

  .new_page {
    margin: 20px auto;
    width: 100%;
    text-align: center;
  }

  .storeCenter_item {
    width: 100%;
    overflow: hidden;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    box-sizing: border-box;
    position: relative;
    .item_checkbox {
      line-height: 36px;
      margin: 0px 10px;
    }
    .Relevancy {
      position: absolute;
      width: 83px;
      height: 25px;
      top: 15px;
      left: 9px;
      background: #ff7d18;
      border-radius: 10px 15px 15px 0px;
      font-size: 12px;
      text-align: center;
      line-height: 25px;
      color: #ffffff;
    }
    .Relevancyblue {
      position: absolute;
      width: 83px;
      height: 25px;
      top: 15px;
      left: 9px;
      background: #146aff;
      border-radius: 10px 15px 15px 0px;
      font-size: 12px;
      text-align: center;
      line-height: 25px;
      color: #ffffff;
    }
    .storeCenter_item_top {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      box-sizing: border-box;
      padding-bottom: 14px;
      position: relative;
      background: #ffffff;
      padding: 16px 20px 10px 10px;
      .storeCenter_item_top_left {
        display: flex;
        flex-direction: row;
        width: 35%;
        .state_one {
          width: 50px;
          height: 18px;
          color: #e00000;
          background: #fce5e5;
          border-radius: 8px;
          font-size: 10px;
          text-align: center;
          line-height: 18px;
          margin-left: 5px;
        }
        .state_two {
          font-weight: 550;
          color: #ff7d18;
          margin-left: 5px;
        }
      }
      .storeCenter_item_top_center {
        width: 1000px;

        .codeName_size {
          margin-right: 3px;
          font-size: 14px;
        }
        .file_pdf {
          width: 13px;
          height: 13px;
          position: relative;
          top: 1px;
          cursor: pointer;
        }
      }
      .storeCenter_item_top_right {
        width: 15%;
        align-items: stretch;
        height: 100%;
        .account_cycle {
          text-align: right;
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;

          /* 灰色字体 */

          color: #969495;
        }
        .operation_area {
          margin-top: 30px;
          float: right;
          .buttom_right {
            height: 36px;
            line-height: 1px;
          }
          .edit {
            padding-left: 20px;
            color: #4e93fb;
            cursor: pointer;
          }
          .frozen {
            padding-left: 20px;
            color: #fd5469;
            cursor: pointer;
          }
        }
      }
    }
  }
  .storeCenter_item_top_left_order {
    display: flex;
    align-items: center;
    font-size: 14px;

    .item_orderClass {
      margin-left: 10px;
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #6e6e6e;
      img {
        margin-right: 5px;
        width: 14px;
        height: 14px;
      }
    }
    .order_name {
      border-radius: 4px;
      padding: 5px;
    }
    .am {
      background: #f4edff;
      color: #904efb;
    }
    .rc {
      background: #e7fce5;
      color: #12dc00;
    }
    .xs {
      background: #edf4ff;
      color: #4e93fb;
    }
    .zj {
      background: #fff2e8;
      color: #ff7d18;
    }
    .hd {
      background: #feebeb;
      color: #f53939;
    }
    .zd {
      background: #e5f2f8;
      color: #0079bd;
    }
    .hy {
      background: #fbf5f0;
      color: #d39869;
    }
    .bg {
      color: #df08bd;
      background: #fce6f8;
    }
    .order_num {
      color: #333333;
      font-size: 18px;
      font-weight: 550;
      margin-left: 10px;
    }
    .compny_name {
      color: #999999;
      font-size: 14px;
    }
  }
  // 二级标签样式
  .second_level {
    width: 100%;
    height: 4.5vh;
    // background: #f1f8ff;
  }
  .ckeckbox_text {
    margin-left: 10px;
    /deep/.el-checkbox__inner {
      width: 18px;
      height: 18px;
    }
    /deep/.el-checkbox__label {
      color: #333;
    }
    /deep/.el-checkbox__inner::after {
      height: 10px;
      left: 6px;
    }
  }
}
</style>
